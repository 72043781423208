<template>
	<div class="bid-item-component">
		<v-row no-gutters class="mt-4 d-flex">
			<p class="heading-rf13">Item</p>
			<p class="heading-rf13 ml-auto mr-2" style="width:40px;">Qty</p>
			<p class="heading-rf13" style="width:80px;">Unit Price</p>
		</v-row>

		<v-row v-for="(item, index) in biditems" v-bind:key="index" no-gutters>
			<v-row no-gutters class="bid-item-box d-flex align-center">
				<v-col cols="7" v-bind:class="modification(index)" class="pa-0">
					<p class="heading-mf15">{{ item.name }}</p>
				</v-col>
				<v-col cols="5" class="pa-0 d-flex align-center justify-end">
					<div v-if="!item.show_note && !value.check_bid" class="outline-box mr-2">
						<img :src="$store.state.icons.icons['Message']" v-on:click="item.show_note = true" alt />
					</div>
					<div class="mr-2" style="width:40px;">
						<v-text-field
							class="inputNumber"
							@blur="showNotes(index)"
							@keypress="digitOnly"
							priceValidation
							align-center
							single-line
							outlined
							dense
							autocomplete="false"
							:readonly="value.check_bid"
							hide-details
							height="32"
							:max="item.qty"
							color="#CACEDA"
							required
							type="number"
							v-model.number="item.qty"
							:rules="qtyRules"
						></v-text-field>
					</div>
					<div style="width:80px;">
						<app-my-currency
							v-model="item.price"
							:qty="item.qty"
							:check_bid="value.check_bid"
							:rules="priceRules[index]"
						></app-my-currency>
					</div>
				</v-col>

				<transition name="notes" tag="div">
					<v-col v-if="item.show_note" cols="12">
						<v-textarea
							@blur="showNote(index)"
							solo
							auto-grow
							autofocus
							autocomplete="false"
							:readonly="value.check_bid"
							color="#7587A3"
							:hide-details="errorShow(index)"
							rows="2"
							placeholder="Comments"
							v-model.trim="item.notes"
							:rules="noteRules[index]"
						></v-textarea>
					</v-col>
				</transition>
			</v-row>
		</v-row>
	</div>
</template>

<script>
import Vue from "vue";
Vue.filter("formatPrice", function(value) {
	if (value) {
		return (
			"$ " +
			value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
		);
	}
});

export default {
	props: {
		value: Object,
		listitems: Array
	},

	data() {
		return {
			qtyRules: [v => /^[0-9].*$/.test(v) || "Input digit only"],
			priceRules: {},
			noteRules: {}
		};
	},

	computed: {
		biditems() {
			this.value.biditems.forEach((item, index) => {
				if (item.qty === 0) {
					item.price = 0;
					this.priceRules[index] = [v => !!v || "Price is required"];
				} else {
					this.priceRules[index] = [
						v => !!v || "Price is required",
						v => /^[^0]/.test(v) || "not zero"
					];
				}
			});

			return this.value.biditems;
		}
	},

	methods: {
		showNote(index) {
			this.value.biditems[index].notes === ""
				? (this.value.biditems[index].notes = null)
				: "";
			if (
				this.value.biditems[index].notes === null &&
				this.value.biditems[index].modification === "N"
			) {
				this.noteRules[index] = [];
				this.value.biditems[index].show_note = false;
			}

			if (this.value.biditems[index].modification === "Y") {
				this.value.biditems[index].show_note = true;
				this.noteRules[index] = [v => !!v || "Comments is required"];
			} else {
				this.value.biditems[index].show_note = false;
			}
		},

		showNotes(index) {
			if (this.value.biditems[index].qty > this.listitems[index].qty) {
				this.value.biditems[index].qty = this.listitems[index].qty;
			}

			if (this.value.biditems[index].modification === "Y") {
				this.value.biditems[index].show_note = true;
				this.noteRules[index] = [v => !!v || "Comments is required"];
			} else if (this.value.biditems[index].modification === "N") {
				this.value.biditems[index].show_note = false;
				this.noteRules[index] = [];
			}

			if (
				this.value.biditems[index].qty === this.listitems[index].qty &&
				this.value.biditems[index].notes === null
			) {
				this.value.biditems[index].modification = "N";
				this.value.biditems[index].show_note = false;
				this.noteRules[index] = [];
			}
		},

		modification(index) {
			if (this.value.biditems[index].qty === 0) {
				this.value.biditems[index].modification = "Y";
				return "text-line";
			} else if (
				this.value.biditems[index].qty != this.listitems[index].qty ||
				this.value.biditems[index].notes != null
			) {
				this.value.biditems[index].modification = "Y";
				return "text-warnning";
			} else {
				this.value.biditems[index].modification = "N";
				// this.value.biditems[index].show_note = false;
				this.noteRules[index] = [];
				return "abc";
			}
		},

		errorShow(index) {
			if (
				this.value.biditems[index].modification === "N" ||
				this.value.biditems[index].notes != null
			) {
				return true;
			} else if (this.value.biditems[index].modification === "Y") {
				return false;
			}
		},

		digitOnly($event) {
			let keyCode = $event.keyCode ? $event.keyCode : $event.which;
			if (keyCode < 48 || keyCode > 57) {
				$event.preventDefault();
			}
		}
	}
};
</script>

<style lang="scss">
</style>